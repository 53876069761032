import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SessionDataProvider } from '@providers/session-data/session-data';
import { ErrorResponseGeneral } from '@models/responses/response-general';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { ModalService } from '@providers/modal-services/modal.service';
import { SnackbarService } from '@providers/snackbar-services/snackbar.services';
import { Router } from '@angular/router';
import { ROUTES_ADMIN } from '@constants/routes';
import { RequestErrors } from '@enums/status.enum';
import { IdpAccessTokenResponse } from '@models/responses/login-response';
import { StorageKeys } from '@enums/storage.enum';
import { StorageService } from '@providers/storage/storage.service';
import { IdPResponseError } from '@enums/idpresponseerrors.enum';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { TranslationService } from '@providers/translation/translation.service';
import { ConfirmCredentialsComponent } from '@components/confirm-credentials/confirm-credentials.component';
import { isLoginEndpoint } from '@data/methods-general';

@Injectable()
export class ConnectionManager {
  BASE_URL = environment.serverUrlAdmin;
  BASE_URL_API = environment.serverUrlPasser;
  BASE_URL_CAT = environment.serverUrlCatalogs;
  BASE_URL_DRIVER = environment.serverUrlDriver;
  BASE_URL_IDP = environment.passerIdP.options.serverUrl

  //Idp
  IDP_TOKEN         = '/token'
  ME                ='/me'
  GET_SUBSCRIPTIONS_ACCOUNT  = '/api/providers/Passer.Management/accounts/{{account}}/subscriptions'
  // Account
  SAVE_CANON         = '/account/createCanonToReview';
  GET_CANON_INFO     = '/account/getCompanyCanon';
  GET_CANON_UP_INFO  = '/account/getCompanyCheckoutCanon';
  SET_DATA_BANK      = '/account/setbankdata';
  SET_SOL_CREDENTIALS = '/account/merchant/request';
  GET_CHECK_COD_BANK = '/account/getCheckCodeBankData';
  GET_BANK_DATA      = '/account/getbankdata';
  DELETE_CANON       = '/account/deleteCanon';
  VERIFY_EXIST_TRACT = '/account/verifyExistTract';
  SEND_CODE_UPDATE   = '/account/sendOtp';
  SET_PROFILE_EMPLOYEE  = '/account/updateUserData';

  // Alert
  CREATE_ALERT = '/alert';

  // Company
  COMPANY_DATA          = '/company';
  GET_PHOTO_COPNY       = '/company/getAvatar';
  GET_AGENT_DATA        = '/company/getAgentByIdCompany';
  GET_CANON_MIN_DATA    = '/company/getCanonesByIdCompany';
  GET_CANON_UP_MIN_DATA = '/company/getCheckoutCanonesByIdCompany';
  GET_JOURN_BY_COMPID   = '/company/getJourneysByCompanyId';
  GET_OFF_DATES         = '/company/getOffDates';
  DELETE_COMP_SERV      = '/company/delCompanyService';
  UPDATE_JOUR_COMP      = '/company/updateJourneyCompany';
  ADD_JOUR_COMP         = '/company/addJourneyCompany';
  GET_SERV_SCH_BY_COSCE = '/company/getServiceSchedulesByCosce';
  GET_SERV_SCH          = '/company/GetServiceSchedules';
  GET_SCHED_BY_COMPID   = '/company/getSchedulesByIdCompany';
  GET_AND_UPDATE_CONDITIONS = '/company/tickets/setup';
  GET_User_Company_Active_Ticket = '/company/tickets';
  GET_ADMIN_LIST = '/company/copny/subscriptionadmins';
  VERIFY_PIN_ADMIN = '/company/copny/subscriptionadminpincheck';
  UPDATE_AVATAR_COVER = '/company/{{photo}}';

  // Conciliation
  GET_CONCI       = '/conciliation/getConciliations';
  SAVE_CONCI      = '/conciliation/saveConciliation';
  GET_CONCI_BY_PK = '/conciliation/getConciliationbypk';
  GET_DATA_IN_PROGRESS = '/conciliation/getMoneyOnRoad';
  GET_DATA_FINISHED = '/conciliation/getMoneyOnFinished';
  GET_DETAILS_ECOMMERCE = '/conciliation/getEcommerceDetails';
  GET_DETAILS_BY_TRACT = '/conciliation/getMoneyForRoleByTract';
  GET_DATA_PHYSICAL = '/conciliation/getMoneyFromOffice';
  GET_DATA_AGENTS = '/conciliation/getMoneyFromSellerByOffice';
  GET_DATA_ROLES_PHYSICAL = '/conciliation/getMoneyFromRoleByOffice';
  GET_DATA_ECOMMERCE = '/conciliation/getMoneyFromEcommerce';
  GET_DATA_ROLES_ECOMMERCE = '/conciliation/getMoneyFromEcommerceGroupByRoleFromDate';

  // Countries
  GET_ALL_COUNTRY       = '/countries/getCountries';
  GET_ALL_STATUS        = '/countries/getStatesAndCountyByCountry';
  GET_COUNTRY_BY_COUNTY = '/countries/getInfoBycounty';

  // Driver
  driverSettings = '/driver/setup';

  // Employee
  SAVE_LICENSE          = '/employee/updateDriverLicense';
  DRIVER_ACTIONS        = '/driver';
  GET_COMMENTS          = '/driver/reviews';
  GET_ALL_DRIVERS       = '/driver/company';
  CHANGE_ACCESS_DRIVER  = '/driver/driverAppAccess';
  CHANGE_STATUS_DRIVER  = '/employee/updateDriverStatus';
  UPDATE_DRIVER_DATA    = '/employee/updateDriver';
  UPDATE_DRIVER_LICENSE = '/employee/modifyDriverLicense';
  GET_VENDORS           = '/employee/getcompanyVendors';

  // Gets
  UPDATE_REPORT_DATA  = '/reports/updateData';
  SEND_REPORT         = '/reports/accountant/report';
  GETs                = '/reports/getData';
  GET_REPORT_PENDING  = 'pendingReports';
  GET_ONE_BASE64      = '/system/ConciliationReport';
  GET_REPORT_HISTORY  = 'historyReports';
  UPDATE_QUERY_REPORT = 'updateConcilationReportCounting';

  TOKEN_LIQ_ONE = '2322d9a22c3e21545d299ff2376b5f3d';

  // Invoice
  GET_ROLE_RESUME_TICKET = '/invoice/getRoleResumetoReportTicket';
  GET_ROLE_RESUME_URBAN  = '/invoice/getRoleResumetoReportURBAN';
  GET_ROLE_RESUME        = '/invoice/getRoleResume';
  GET_ELECT_INV          = '/invoice/getElectronicInvoices';

  // Journey
  CHANGE_STATE_JOUR       = '/journey/changeState';
  GET_WAYS_BR             = '/journey/getWaysByRoute';
  GET_WAYS_BRCJ           = '/journey/getWaysByRCJ';
  GET_JOURN_BY_RCOMP_SERV = '/journey/getJourneysByRouteAndCompany';
  UPDATE_BASIC_CONFIG     = '/journey/basicConfig';
  UPDATE_MATRIX_JOURNEY   = '/journey/map-tractfare';
  CREATE_ALL_ROUTE        = '/journey';
  GET_ORIGINS             = '/journey/departure';
  GET_DESTINATIONS        = '/journey/arrival';
  GET_TRACTFARE           = '/tractfare';
  GET_OLDER_MATRIX        = '/journey/older-matrix';

  // oauth
  SIGN_IN           = '/oauth/user/signIn';
  SEND_CODE         = '/oauth/user/otp';
  GET_CHANG_COPNY   = '/oauth/user/changeUserCompany';
  CHANGE_TWO_STEPS  = '/oauth/user/changeTwoSteps';
  DELETE_USER_SBC   = '/oauth/user/deleteUserSessionsByCompany';
  GET_USER_SBCO     = '/oauth/user/getUserSessionsBycompanyOld';
  GET_USER_SBC      = '/oauth/user/getUserSessionsBycompany';
  CHANGE_EMP_NL     = '/oauth/user/change_employee_name_lastnames';
  CHANGE_USER_PHONE = '/oauth/user/changeUserPhone';
  CHANGE_USER_PASS  = '/oauth/user/changeUserPassword';
  CHANGE_USER_EM    = '/oauth/user/changeUserEmail';
  SIGN_OUT          = '/oauth/user/signOut';
  VERIFY_PASSWORD   = '/oauth//user/verifyPassword';
  VERIFY_OTP        = '/oauth//user/verifyOtp';

  // Office
  GET_OFFICE       = '/offices';
  UPDATE_CONTACT   = '/offices/contact';
  GET_OFFICES_USER = '/offices/user';
  GET_PLACE_TYPES  = '/placeTypes';
  OPEN_CASH        = '/offices/me/opencash';
  GET_DRIVERS_LINKED = '/offices/closeDriverCash';
  GET_CLOSING_DRIVER = '/offices/driversPendingToCloseCash';
  GET_CLOSING_SALES = '/offices/me/closedCash';
  CLOSE_LOCATION = '/offices/me/closeCashManual';
  GET_SALES_BREAKDOWN = '/offices/me/salesByTract';
  GET_DRIVERS_BREAKDOWN = '/offices/driverCashDetail';

  // PAYMENTS
  SAVE_PAYMENT_METHOD  = '/paymentMethod';
  GET_HISTORY_PAYMENTS = '/paymentmethod/billings';

  // Roles
  GET_ROLES_BY           = '/roles';
  GET_TICKETS_ROLE       = '/roles/tickets';
  SEND_REPORT_ROLES      = '/roles/email';
  GET_ALL_UNITS_POS      = '/roles/alltransportations';
  GET_UNITS              = '/roles/getCompanyUnits';
  GET_ACTIVE_ROLE_UNIT   = '/roles/getActiveRoleUnit';
  GET_ACTIVE_ROLE_VALID = '/roles/activeRoleUnit';
  GET_ROLE_RECAUDO       = '/roles/getRolesRecaudo';
  GET_COMP_SERV_FOR_ROLE = '/roles/getCompanyServicesForRoles';
  GET_ROLE_ACTUAL        = '/roles/getRolesProfitsByDate';
  GET_ROLE_BY            = '/roles/getRolesProfits';
  INSERT_ROLE            = '/roles/createRole';
  GET_UNITS_AVAIL        = '/roles/getTransportationsAvailability';
  GET_DRIVERS_AVAIL      = '/roles/getDriversAvailability';
  DELETE_ROLE            = '/roles/deleteRole';
  MOVE_DRIVER            = '/roles/moveDriver';
  REMOVE_DRIVER          = '/roles/RemoveDriver';
  GET_ACTIVE_ROLE_BDOU   = '/roles/getActiveRolesByDriverOrUnit';
  HAS_OPE_OTICK_BY_BM    = '/roles/hasOperativeRoleOrTicketsByBusModel';
  HAS_OPE_OTICK          = '/roles/hasOperativeRoleOrTickets';
  TRASB_ROLE             = '/roles/transferRole';
  UNIT_ROLES_BY_COMPANY  = '/roles/getNearestRolesByTransportation';
  START_TRIP             = '/roles/restartRoleTripStatus';

  // Routes
  GET_ID_ROUTES       = '/routes/getIDRoutesByCompany';
  GET_INFO_RWTC       = '/routes/getInfoRWTC';
  VERIFY_ROUTE_EXISTS = '/routes/verifyExists';
  GET_TRACTS_WP       = '/routes/getTractsWithPrice';
  GET_ROUTES_WP       = '/routes/getRoutesWithPrice';
  GET_ONE_CRJW_INFO   = '/routes/getOneCRJWInfo';
  GET_ROUTES_BY_COMP  = '/routes/getRoutesByCompany';
  GET_STOPS           = '/routes/getRouteStops';
  GET_TRACTS_XYWP     = '/routes/getTractsXYWithPrice';
  GET_COMP_SERV_ROLE  = '/routes/getCompServForRole';
  GET_RESTRICTIONS    = '/routes/restrictions';
  GET_WAYS            = '/routes/ways';
  GET_COMPANY_SERVICES_ROLE = '/routes/companyservice';
  GET_RESTRICTION_DATA = '/routes/restrictions/alert/text';
  SEND_NOTIFICATION_RESTRICTION = '/routes/restrictions/notification';
  GET_ROUTE_POINTS = '/routes/getRoutePoints';
  GET_ROUTE_POINT_BY_ROLE = '/routes/getRoutePointsByRole';

  // Service
  GET_DEPARTURES = '/service/departure';
  GET_ARRIVAL = '/service/arrival';
  GET_WAYS_SERVICE = '/service/ways';
  GET_SCHEDULE_LIST = '/service/schedules';
  GET_AUTHORIZED_STOPS = '/service/authorizedstops';

  // Suscription
  GET_SUSCRIPTION = '/suscription/company';
  DEACTIVATE_SUSCRIPTION = '/suscription/request/suspend';

  // System
  RESET_PASS          = '/api/providers/Passer.Authorization/recovery/password';
  RESET_ALL_PASS      = '/system/ResetAllPasswords';
  GET_PHOTO           = '/system/GetUserPhoto';
  GET_FACT_ELECT_DATA = '/system/getFactElectData';
  SET_EMPL_PHOTO      = '/system/SetEmployeePhoto';
  SET_FACT_ELECT_DATA = '/system/SetFactElectData';
  SET_ROLE_BUSER      = '/system/SetRoleByUser';
  SET_COPNY_BY_ROLE   = '/system/setCompaniesByUser';
  NEW_USER     = '/system/newUser';
  NEW_ROLE     = '/system/NewRole';
  DELETE_USER  = '/system/delUser';
  GET_US_LOG   = '/system/GetUserLog';
  DELETE_OROLE = '/system/delOwnRole';
  GET_COPNYS   = '/system/loadSubscription';
  GET_USER = '/system/getUser'

  // Tickets
  GET_TICKETS = '/tickets';
  GET_TICKS_SOLD_BDJOSV = '/tickets/getTicketsSoldByDJOSV';
  BUS_TICK_BY_ADMIN     = '/tickets/buyTicketByAdmin';
  VAL_MOB               = '/tickets/validateMobile';
  GET_TICK_DETAIL_BDUNC = '/tickets/getTicketDetailsByDUNC';
  GET_TICKS_BNUMT       = '/tickets/getTicketsbyNumTicket';
  GET_CLIENT_NWBT       = '/tickets/getClientNamesWithBoughtTickets';
  GET_SPECIF_TICK_BY_ID = '/tickets/getSpecificTicketById';
  GET_BUS_INFO          = '/tickets/getBusInfo';
  GET_TICKET_BY_ID      = '/tickets/getTicketById';
  CALCULATE_PRICE       = '/tickets/calculatePrice';
  GET_USER_DATA_BUYTICKET = '/tickets/user';
  CANCEL_TICKET = '/tickets/cancelTicket';

  // Transportation
  MODIFY_NUMB_TRANSPOR = '/transportation/ModifyUnitNumberTransportation';
  CHANGE_STATUS_TRANSP = '/transportation/changeTransportationStatus';
  GET_BRANDS           = '/transportation/getbusesmanufacturers';
  GET_MODEL_BB         = '/Transportation/getModelByBus';
  GET_ALL_UNITS_BM     = '/Transportation/getAllUnitsByModel';
  GET_ALL_MODELS       = '/transportation/getAllModels';
  GET_BMODULES         = '/transportation/getModels';
  GET_TRANSP_WH_MODEL  = '/Transportation/getTransportationWithoutModel';
  GET_INSERT_TRANSPOR  = '/Transportation/getCreatedTransportation';
  SET_BMODEL           = '/transportation/setBusModel';
  INSERT_TRANSPOR      = '/transportation/createTransportation';
  INSERT_MODEL         = '/transportation/createModel';
  DELETE_MODEL         = '/transportation/deleteModel';
  DELETE_TRANSPOR      = '/transportation/deletetransportation';
  UPDATE_STATUS_VALIDATOR = '/transportation/validator';
  CREATE_BUS_MODEL_UNITS = '/transportation';

  // VALIDATOR
  GET_ASSIGNED_UID = '/validator/getAssigneds';
  GET_TRANSPORTATION_TO_ASSIGN = '/validator/transportations';
  CREATE_KEYS = '/validatorkey';
  DETECT_VALIDATOR = '/validator/detect';
  ASSIGN_VALIDATOR = '/validatorkey/assign';
  VALIDATOR_TYPE = '/validatorkey/types';
  REASSIGN_VALIDATOR = '/validatorkey/transportation';
  REMOVE_VALIDATOR = '/validator';
  UPDATE_PHONE = '/validatorkey/phone';

  // MAPS
  GET_DATA_ADDRESS_GEOCODE = 'https://maps.googleapis.com/maps/api/geocode/json';

  // utils
  GET_STATES_AND_COUNTY = '/utils/getStatesAndCountyByCountry';

  // catalogs
  GET_COUNTRIES = '/countries';
  GET_TYPE_ID = '/identificationTypes';
  GET_BUSSINESS_ENTITY_TYPE = '/businessEntityTypes';

  // driver
  SEND_SMS_DRIVER = '/auth/sendEmailSMS';

  // OperatorNotifications
  OPERATOR_NOTIFICATIONS = '/operatorNotifications'

  defaultHeaders = {
    'Content-type': 'application/json; charset=UTF-8',
    'Accept-Language': 'es',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept, access-control-allow-origin',
    'Access-Control-Allow-Credentials':  'true',
    'timeout': `${60000}`
  };
  abortController = new AbortController();
  hasAborted = false;
  isRefreshing = false;

  constructor(
    private modals: ModalService,
    private loading: LoaderManagmentService,
    private router: Router,
    private sessionData: SessionDataProvider,
    private snackbar: SnackbarService,
    private storage: StorageService,
    private translation: TranslationService,
    private userService: UserManagerService
  ) { }

  async getIdpFetch(url: string, retryNumbers = 3 ): Promise<any> {
    this.hasAborted = false;
    this.abortController = new AbortController();
    const options = {
      method: 'GET',
      timeout: 6000,
      headers: {...this.setIdpHeadersWithToken("application/x-www-form-urlencoded")},
      signal: this.abortController.signal
    };
    return await this.fetch_retry(url, options, retryNumbers, true);
  }

  async postIdpFetch(url: string, data: any, retryNumbers = 3, isLogin: boolean = false ): Promise<any> {
    this.hasAborted = false;
    this.abortController = new AbortController();
    const options = {
      method: 'POST',
      timeout: 6000,
      body: this.getParams(data),
      //Segun el grant_type se debe sebe elegir los heder
      headers: data.grant_type == 'refresh_token' ? {...this.setIdpHeadersWithBasic("application/x-www-form-urlencoded")} :{...this.setIdpHeaders("application/x-www-form-urlencoded")},
      signal: this.abortController.signal
    };
    return await this.fetch_retry(url, options, retryNumbers, isLogin);
  }

  async postFetch(url, data, retryNumbers = 3, isLogin: boolean = false): Promise<any> {
    this.hasAborted = false;
    this.abortController = new AbortController();
    const options = {
      method: 'POST',
      timeout: 6000,
      body: JSON.stringify(data),
      headers: {...this.setHeaders()},
      signal: this.abortController.signal
    };
    return await this.fetch_retry(url, options, retryNumbers, isLogin);
  }

  async putFetch(url: string, data: any, retryNumbers = 3, isLogin: boolean = false): Promise<any> {
    const options = {
      method: 'PUT',
      body: JSON.stringify(
        {
          ...data,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }),
      headers : { ...this.setHeaders() }
    };
    return await this.fetch_retry(url, options, retryNumbers, isLogin);
  }

  async getFetch(url: string, data: any, retryNumbers = 3, isLogin: boolean = false): Promise<any> {
    let strinKeys = this.getParams(data);
    const options = {
      method: 'GET',
      headers : { ...this.setHeaders() }
    };
    return await this.fetch_retry(`${url}?${strinKeys}`, options, retryNumbers, isLogin);
  }

  async deleteFetch(url: string, data: any, retryNumbers = 3, isLogin: boolean = false): Promise<any> {
    const options = {
      method: 'DELETE',
      body: JSON.stringify(
        {
          ...data,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }),
      headers : { ...this.setHeaders() }
    };
    return await this.fetch_retry(url, options, retryNumbers, isLogin);
  }

  async deleteRquestQueryFetch(url: string, data: any, retryNumbers = 3, isLogin: boolean = false): Promise<any> {
    let strinKeys = this.getParams(data);
    const options = {
      method: 'DELETE',
      headers : { ...this.setHeaders() }
    };
    return await this.fetch_retry(`${url}?${strinKeys}`, options, retryNumbers, isLogin);
  }

  async getFetchOut(url: string, data: any, retryNumbers = 5, isLogin: boolean = false): Promise<any> {
    const finalData = {...data};
    const keys = Object.keys(finalData);
    let strinKeys = '';
    if(keys.length > 0){
      strinKeys = keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(finalData[key]))
      .join('&');
    }
    const options = {
      method: 'GET',
      headers : {}
    };
    return await this.fetch_retry(`${url}?${strinKeys}`, options, retryNumbers, isLogin);
  }

  getParams(data): string {
    const finalData = {
      ...data,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    const keys = Object.keys(finalData);
    let strinKeys = '';
    if(keys.length > 0){
      strinKeys = keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(finalData[key]))
      .join('&');
    }

    return strinKeys;
  }

  showErrorCatch(error, catchOpt = 0): any {
    if(catchOpt !== 3 && catchOpt !== 4) {
      this.loading.dismissLoading();
      this.loading.dismissSkeleton();
    }
    try {
      const errorResponse: ErrorResponseGeneral = JSON.parse(error);
      if (errorResponse?.data === 'M' || ((catchOpt === 1 || catchOpt === 4) && errorResponse?.message && errorResponse?.description)) {
        this.snackbar.showSnackBar(errorResponse.message, errorResponse.description);
        return;
      }
      else return errorResponse;
    } catch (e) {
      this.modals.showAlert(false);
      return;
    }
  }

  fetchReviewStatus(response, catchOpt?): any {
    try{
      if (typeof response !== 'string' && !this.sessionData.isLogged) {
        return;
      }
      const dataResponse = JSON.parse(response);
      const status = `${dataResponse?.status ?? null}`;
      switch (status) {
        case RequestErrors.Forbidden: {
          this.router.navigate([ROUTES_ADMIN.SUSPENDED_ACCESS]);
          return;
        }
        case RequestErrors.GatewayTimeout:
        case RequestErrors.BadGateway: {
          // 504 - TIME-OUT-SERVER
          // 502 - Servicio caido / Server online
          const {global} = this.translation.languageMessage;
          let error: any = {
            success: false,
            data: 'M',
            description: (status === '504' ? global.error_timeoutD : global.error_maintenanceD),
            message: (status === '504' ? global.error_timeoutT : global.error_maintenanceT)
          };
          error = JSON.stringify(error);
          return this.showErrorCatch(error, catchOpt);
        }
        case RequestErrors.BadRequest: {
          if (dataResponse?.isUnauthorized) {
            return;
          } else {
            return this.showErrorCatch(response, catchOpt);
          }
        }
        default: return this.showErrorCatch(response, catchOpt);
      }
    } catch(errorr){
      return this.showErrorCatch(errorr, catchOpt);
    }
  }

  async handle401Error(): Promise<any> {
    const sessionActive = this.sessionData.refreshTokenIdp && this.sessionData.subscriptionIdp?._id;
    if (!this.isRefreshing && sessionActive) {
      this.isRefreshing = true;
      try {
        const response: IdpAccessTokenResponse = await this.postIdpFetch(
          `${this.BASE_URL_IDP}${this.IDP_TOKEN}`,
          {
            grant_type: "refresh_token",
            refresh_token: this.sessionData.refreshTokenIdp,
            scope: environment.passerIdP.options.scope,
            suscription: this.sessionData.subscriptionIdp._id,
          },
          0
        );
        this.isRefreshing = false;
        this.sessionData.token = response.access_token;
        this.sessionData.tokenType = response.token_type;
        this.sessionData.refreshTokenIdp = response.refresh_token;
        await this.storage.setData(StorageKeys.TOKEN, this.sessionData.token);
        await this.storage.setData(StorageKeys.TOKEN_TYPE, this.sessionData.tokenType);
        await this.storage.setData(StorageKeys.REFRESH_TOKEN_IDP, this.sessionData.refreshTokenIdp);
        return true;
      } catch(err) {
        const reLogin = await this.modals.showAlertCompReturn(ConfirmCredentialsComponent, null);
        if (reLogin) {
          this.isRefreshing = false;
          return true;
        } else {
          await this.logOut();
          this.isRefreshing = false;
          this.abortController.abort();
          throw err;
        }
      }
    } else {
      if (sessionActive) {
        return true;
      } else {
        this.abortController.abort();
      }
    }
  }

  // tslint:disable-next-line: variable-name
  fetch_retry = async (url, options, retryNumbers, isLogin?): Promise<any> => {
    if (!this.sessionData.isLogged && !isLogin) {
      return;
    } else {
      const userCached = await this.storage.getMyData(StorageKeys.COOKIE_NAME);
      const userEmailMemory = this.userService.email;
      const subscriptionCached = await this.storage.getMyData(StorageKeys.SUBSCRIPTION_IDP);
      const subscriptionMemory = this.sessionData.subscriptionIdp;
      if ( (userEmailMemory !== userCached?.email || subscriptionMemory?._id !== subscriptionCached?._id) && !isLogin && !isLoginEndpoint(url)) {
        if (!Boolean(userEmailMemory) || !Boolean(userCached?.email) || !Boolean(subscriptionMemory?._id) || !Boolean(subscriptionCached?._id)) {
          // Si alguno de estos datos no existe, posiblemente quiere decir que se cerró sesión en otra pestaña,
          // por lo que se refresca la página para que el auth se encargue de botarlo al login
          location.reload();
        }
        const {
          actions: { understood },
          alerts: { anotherSessionTitle, anotherSessionDescription }
        } = this.translation.languageMessage;
        await this.modals.showAlertCompReturn(NormalModalComponent, {
          title: anotherSessionTitle,
          message: anotherSessionDescription,
          btnSave: understood
        });
        location.reload();
      } else {
        try {
          const response = await fetch(url, options);
          if(response.ok){
            return await response.json();
          }else{
            const {status} = response;
            let data: any = {};
            if (`${status}` !== RequestErrors.Unauthorized) {
              data = await response.json();
            } else if (retryNumbers > 0) {
              try {
                const response: boolean = await this.handle401Error();
                if (response) {
                  const { headers } = options;
                  if (headers.Authorization) {
                    options.headers.Authorization = this.setAuthorizationRetry(headers.Authorization);
                  }
                  return await this.fetch_retry(url, options, 0);
                }
              } catch(err) {
                throw err;
              }
            }

            data.status = status;
            retryNumbers = 0;
            throw this.fecthErrorThrow(false, data);
          }
        } catch(err) {
            if (retryNumbers === 1) {
              throw this.fecthErrorThrow(false);
            }
            if (retryNumbers === 0){
              this.setErrorIsLogin(err, url, retryNumbers);
            }

            this.setErrorIsLogin(err, url, retryNumbers);

            return this.fetch_retry(url, options, retryNumbers - 1);
        }
      }
    }
  }

  fecthErrorThrow(out: boolean, err?): string {
    if(err){
      return JSON.stringify(err);
    }else{
      const {alerts, routes} = this.translation.languageMessage;
      let error: any = {success: false, data: 'M', description: !!out ? routes.alerts.err_maps : alerts.errDTSD, message: alerts.errDTST};
      error = JSON.stringify(error);
      return error;
    }
  }

  setErrorIsLogin(err: any, url: string, retry): any {
    if (err && typeof err === 'string') {
      const error = JSON.parse(err);
      if (error?.status === 400 && error?.error === IdPResponseError.InvalidGrant) {
        error.isUnauthorized = !url.includes('/token');
        err = JSON.stringify(error);
        throw err;
      }
    }
    if (retry === 0){
      throw err;
    }
  }

  getActiveDataHeader(): any {
    return {
      idpSub: this.userService.currentUser?.sub ?? this.userService.temporaryIdSub,
      managementGroup: this.sessionData.subscriptionIdp?.managementGroup?._id,
      subscription: this.sessionData.subscriptionIdp?._id
    }
  }

  setHeaders(): any {
    return {
      ...this.defaultHeaders,
      'Accept-Language': this.translation.currentlanguage,
      'Authorization': `Bearer ${this.sessionData.token}`,
      ...this.getActiveDataHeader()
    };
  }

  setIdpHeaders(contentType: string): any {
    return {
      ...this.defaultHeaders,
      'Content-type': contentType,
      'Accept-Language': this.translation.currentlanguage,
      ...this.getActiveDataHeader()
    };
  }

  setIdpHeadersWithToken(contentType: string): any {
    return {
      ...this.defaultHeaders,
      'Content-type': contentType,
      'Accept-Language': this.translation.currentlanguage,
      'Authorization' : `Bearer ${this.sessionData.token}`,
      ...this.getActiveDataHeader()
    };
  }

  setIdpHeadersWithBasic(contentType: string): any {
    const authString = btoa(`${environment.passerIdP.options.clientId}:${environment.passerIdP.options.clientsecret}`);
    return {
      ...this.defaultHeaders,
      'Content-type': contentType,
      'Accept-Language': this.translation.currentlanguage,
      'Authorization': `Basic ${authString}`,
      ...this.getActiveDataHeader()
    };
  }

  setAuthorizationRetry(auth: string, ): string {
    const splitAuth = auth.split(' ');
    switch (splitAuth[0]) {
      case 'Basic': {
        const authString = btoa(`${environment.passerIdP.options.clientId}:${environment.passerIdP.options.clientsecret}`);
        return `Basic ${authString}`;
      }
      case 'Bearer': {
        return `Bearer ${this.sessionData.token}`;
      }
      default: return;
    }
  }

  async logOut(logout?: boolean): Promise<any> {
    this.loading.presentLoading();
    if (!this.userService.currentUser) {
      this.login(logout);
    }
    else {
      try{
        const subscription = this.sessionData.subscriptionIdp?._id;
        await this.postIdpFetch(
          `${this.BASE_URL_IDP}/api/providers/Passer.Authorization/accounts/${this.userService.currentUser?.sub}/logout`,
          { subscription }
        );
        this.login(logout);
      } catch (error) {
        this.login(logout);
      }
    }
  }

  async login(logout?: boolean): Promise<void> {
    this.sessionData.clearAllData();
    this.sessionData.header = false;
    this.modals.closeModal();
    await this.storage.clearAllData();
    this.userService.currentUser = undefined;
    setTimeout(() => {
      this.loading.dismissLoading();
      this.router.navigate([ROUTES_ADMIN.ROOT_ROUTE]);

      if (!logout) {
        const { login: { alerts: { closeT, closeD } }, actions: { understood } } = this.translation.languageMessage;
        this.modals.showAlertComp(NormalModalComponent, {
          title: closeT,
          message: closeD,
          btnSave: understood
        });
      }
    }, 100);
  }
}
