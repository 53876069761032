import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';
import { StorageKeys } from '@enums/storage.enum';
import { ITEM_CACHE } from '@data/times';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: LocalStorageService) { }

  async getMyData(key: string): Promise<any> {
    try {
      const response: any = await this.storage.get(key);
      return response;
    } catch (_) {
      throw null;
    }
  }

  async setData(key: string, data: any): Promise<boolean> {
    try {
      return await this.storage.set(key, data);
    } catch (e) {
      throw false;
    }
  }

  async clearData(key: string): Promise<boolean> {
    try {
      await this.storage.remove(key);
      return true;
    } catch (e) {
      throw false;
    }
  }

  async clearAllData(): Promise<boolean> {
    try {
      await this.storage.clear();
      return true;
    } catch (e) {
      throw e;
    }
  }

  async deleteCacheChangeAccess(): Promise<void> {
    await this.clearData(ITEM_CACHE.EMPLOYEE_TIME);
    await this.clearData(ITEM_CACHE.EMPLOYEE_LOCAL);
    await this.clearData(ITEM_CACHE.UNITS_TIME);
    await this.clearData(ITEM_CACHE.UNITS_LOCAL);
    await this.clearData(ITEM_CACHE.OFFICE_TIME);
    await this.clearData(ITEM_CACHE.OFFICE_LOCAL);
    await this.clearData(ITEM_CACHE.VENDOR_TIME);
    await this.clearData(ITEM_CACHE.VENDOR_LOCAL);
    await this.clearData(ITEM_CACHE.JOURNEY_TIME);
    await this.clearData(ITEM_CACHE.JOURNEY_LOCAL);
    await this.clearData(ITEM_CACHE.BUS_MODEL_TIME);
    await this.clearData(ITEM_CACHE.BUS_MODEL_LOCAL);
    await this.clearData(ITEM_CACHE.ROUTE_TIME);
    await this.clearData(ITEM_CACHE.ROUTE_LOCAL);
    await this.clearData(ITEM_CACHE.RESTRICTION_TIME);
    await this.clearData(ITEM_CACHE.RESTRICTION_LOCAL);
    await this.clearData(ITEM_CACHE.SETUP_TIME);
    await this.clearData(ITEM_CACHE.SETUP_LOCAL);
  }
}
