import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { StorageKeys } from '@enums/storage.enum';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { RedirectService } from '@providers/redirect/redirect.service';
import { StorageService } from '@providers/storage/storage.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';

@Injectable()
export class AuthGuardRolesService implements CanActivate {

  constructor(
    private connection: ConnectionManager,
    private redirect: RedirectService,
    private storage: StorageService,
    private userService: UserManagerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { role } = route.data;
    try{
      const isLogged = await this.isAuthenticated(role);
      return isLogged;
    } catch(error) {
      this.connection.logOut();
      return false;
    }
  }

  async isAuthenticated(moduleName: string): Promise<boolean> {
    try {
      if (!this.userService.currentUser) {
        const currentUser = await this.storage.getMyData(StorageKeys.COOKIE_NAME);
        this.userService.currentUser = currentUser;
      }

      const hasAccess: boolean = this.userService.currentUser?.privileges.some(module => (module.name === moduleName));
      if (!hasAccess) {
        this.redirect.redirectToInit();
        return false;
      }
      return true;
    }
    catch (err) {
      this.connection.logOut();
      return false;
    }
  }
}
